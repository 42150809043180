@import "color";
@import "font";

body {
  margin: 0;
  font-family: Roboto Regular, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.container-app {
  padding: 0 10%;
  @media (max-width: 1400px) {
    padding: 0 5%;
  }
  @media (max-width: 1200px) {
    padding: 0 15px;
  }
}
.mobile-menu {
  padding: 5px;
  height: 40px;
  margin: auto;
  background-color: white;
  &:focus {
    box-shadow: none;
  }
}
.header-brand {
  background-color: $primary-color;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  &--text {
    font-size: 12px;
    color: white;
    width: 236px;
    white-space: break-spaces;
    text-align: left;
    vertical-align: middle;
  }
  &--btn {
    background-color: white;
    color: black;
    width: 136px;
    height: 40px;
    text-align: center;
    padding: 10px;
    font-size: 12px;
    &:hover {
      box-shadow: inset 0px 0px 6px 0px $primary-color;
      color: $primary-color;
    }
  }
}
li.nav-item {
  cursor: pointer;
  &:hover {
    a.nav-link {
      color: $primary-color;
    }
  }
  a.nav-link {
    text-transform: uppercase;
    padding: 14px !important;
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #373737;
    svg {
      margin-right: 6px;
      margin-top: -3px;
    }
  }
}

/* Dropdown Button */
.dropbtn {
  text-transform: uppercase;
  text-decoration: none;
  color: black;
  padding: 11px 0 !important;
  display: inline-block;
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #373737;
  svg {
    margin-right: 5px;
    margin-top: -3px;
  }
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  width: max-content;
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  display: block;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  text-transform: uppercase;
  text-align: left;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #373737;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: $primary-color;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
  transform: rotateX(1, 0, 0, -90deg);
  -webkit-transform-origin: 0 0 0;
  -moz-transform-origin: 0 0 0;
  -o-transform-origin: 0 0 0;
  -ms-transform-origin: 0 0 0;
  transform-origin: 0 0 0;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  color: $primary-color;
}

// hero banner
.hero-banner {
  height: 240px;
  object-fit: fill;
  width: 100%;
  @media (max-width: 786px) {
    object-fit: cover;
  }
}
.card {
  border-radius: 0%;
  border: none;
  .card-img {
    border-radius: 0%;
  }
  .card-img-overlay {
    .card-title,
    .card-text {
      color: white;
      text-transform: uppercase;
      font-style: normal;
      font-weight: 700;
    }
  }
}

.card-content {
  background-color: $second-color;
  cursor: pointer;
  &:hover {
    background-color: $primary-color;
    color: white;
  }
  .card-title {
    text-transform: uppercase;
  }
}

.f-floating {
  .float-label {
    top: 20px;
    position: relative;
    background-color: white;
    padding: 0;
    width: fit-content;
    margin-left: 15px;
    opacity: 1;
    z-index: 1;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
  }
}
#react-select-2-listbox,
#react-select-3-listbox {
  z-index: 2;
}

#react-select-2-input,
#react-select-3-input {
  height: 46px;
}

.dropdown-select__value-container {
  padding-left: 20px !important;
}

.input-number {
  height: 60px;
  padding-left: 20px;
  &:focus {
    border: 2px solid $primary-color !important;
    box-shadow: none !important;
  }
}

.tra-cuu-btn,
.tra-cuu-btn:hover {
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  height: 60px;
  background-color: $primary-color;
  color: white;
}

.logo-on-footer {
  height: 65px;
}

footer {
  a {
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    color: #373737;
    text-decoration: none;
    cursor: pointer;
  }
}

.tbl-header {
  background-color: #e6f6ff;
  th {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
  }
}

.w-95 {
  width: 95%;
}
.w-5 {
  width: 5%;
}
