@font-face {
    font-family: 'Roboto Regular';
    src: local('Roboto-Regular'),
        url('../assets/font/Roboto-Regular.ttf');
}

@font-face {
    font-family: 'Roboto Light';
    src: local('Roboto Light'),
        url('../assets/font/Roboto-Light.ttf');
}

@font-face {
    font-family: 'Roboto Bold';
    src: local('Roboto Bold'),
        url('../assets/font/Roboto-Bold.ttf');
}